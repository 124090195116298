@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;600&family=Raleway:wght@500;700&display=swap");

body {
  font-family: "Teko", sans-serif;
  font-size: 18px;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

.main-header {
  width: 100%;
  min-height: 6rem;
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 99;
}

.main-logo {
  width: 10rem;
}

.social-header {
  background-color: #88b330;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social-header svg {
  color: #fff;
}

.main-nav {
  transition: 400ms ease-in-out;
  position: relative;
}

.main-nav img {
  width: 7rem;
  transition: 400ms ease-in-out;
}

.sticky-nav {
  height: 4rem;
}

.sticky-nav img {
  width: 6rem;
}

@media screen and (max-width: 995px) {
  .main-nav img {
    width: 6rem;
  }

  .sticky-nav img {
    width: 5rem;
  }
}

.main-nav ul {
  width: 100%;
  z-index: 98;
}

.main-nav ul a {
  font-weight: 700;
}

@media screen and (max-width: 995px) {
  .main-nav ul {
    display: none;
    height: 0;
    transition: height 400ms ease-in-out;
  }
}

.main-nav ul.opened {
  display: block;
  height: 335px;
  position: absolute;
  top: 4rem;
  left: 8%;
  background-color: #fff;
  border-top: 4px solid #88b330;
  max-width: 20rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.main-nav ul.opened li {
  padding: 10px 0;
}

.main-nav ul.opened a {
  font-weight: 500;
  font-size: 0.9rem;
}

.nav-btn {
  width: 23px;
  height: 15px;
  position: relative;
  display: none;
}

@media screen and (max-width: 995px) {
  .nav-btn {
    display: block;
  }
}

.nav-btn span {
  display: inline-block;
  background-color: #000;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
}

.nav-btn span:first-of-type {
  top: 0;
}

.nav-btn span:nth-of-type(2) {
  top: 6.48px;
}

.nav-btn span:nth-of-type(3) {
  bottom: 0;
}

.nav-item {
  margin: 0 5px;
}

.nav-item a {
  font-family: "Raleway", sans-serif;
  padding: 10px;
  width: 100%;
}

.non-gelcoat {
  background-color: #f6fdcf;
}

.gloss-white {
  background-color: #e5e5e5;
}

.matte-elder-white {
  background-color: #eff0e4;
}

.matte-greige {
  background-color: #efefed;
}

.gloss-med-gray {
  background-color: #c0c0c0;
}

.matte-charcoal {
  background-color: #808080;
}

.matte-black {
  background-color: #2d2a2b;
}

.gloss-orange {
  background-color: #ef4407;
}

.matte-red {
  background-color: #bf2026;
}

.matte-dark-blue {
  background-color: #243179;
}
