.MainWrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 5rem;

  > div {
    max-width: 1180px;
    margin: 0 auto;
    margin-bottom: 50px;
    // background-color: #444;
  }

  h1,
  h2 {
    color: #000;
    font-size: 2.2rem;
    font-weight: 900;
  }

  p {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 768px) {
    h1,
    h2 {
      font-size: 3.3rem;
    }
  }
}
